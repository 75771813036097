<template>
  <div :class="rootClasses">
    <span>{{ $t('home.are_you_sure') }}?</span>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'ConfirmBlock',
  props: {
    size: { type: String as () => 'md' | 'lg', default: () => 'lg' },
    noPadding: { type: Boolean, default: () => false },
    noBorder: { type: Boolean, default: () => false },
    noBackground: { type: Boolean, default: () => false },
  },
  setup(props) {
    const rootClasses = computed(() => ({
      'confirm-block': true,
      'confirm-block--no-padding': props.noPadding,
      'confirm-block--no-border': props.noBorder,
      'confirm-block--no-background': props.noBackground,
      [`confirm-block--${props.size}`]: true,
    }))

    return {
      rootClasses,
    }
  },
}
</script>

<style lang="scss">
.confirm-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(#e1e6e8, 0.5);
  border: 1px solid var(--hb-gray2);
  border-radius: 10px;
  padding: 10px 10px 10px 37px;
  font-weight: 700;

  & > div {
    display: flex;
    gap: 10px;
  }

  @include tablet {
    gap: 15px;
  }

  @include mobile-md {
    flex-direction: column;
    padding: 20px 10px 10px;
    gap: 27px;

    > div {
      width: 100%;
    }

    .hb-btn {
      display: block;
      width: 100%;
    }
  }

  &--md {
    font-size: 14px;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-border {
    border: none;
  }

  &--no-background {
    background-color: transparent;
  }
}
</style>
