<template>
  <div class="home-card-cancellation">
    <div class="home-card-cancellation__head">
      <strong>Free cancellation</strong>
      <span v-if="isCancellable && cancellableUntil" v-tooltip="formattedDate">
        {{ diffLabel }}
      </span>
    </div>

    <template v-if="isCancellable">
      <hb-btn v-if="!showConfirm" @click="showConfirm = true">
        <span>{{ $t('home.cancellation') }}</span>
      </hb-btn>
      <confirm-block v-else size="md" no-background no-border no-padding>
        <hb-btn
          theme="secondary"
          size="sm"
          type="button"
          class="hb-btn-confirm"
          :loading="pending"
          @click="cancel()"
        >
          <span>{{ $t('label.yes') }}</span>
        </hb-btn>
        <hb-btn
          theme="light"
          type="button"
          size="sm"
          class="hb-btn-deny"
          :disabled="pending"
          @click="showConfirm = false"
        >
          <span>{{ $t('label.no') }}</span>
        </hb-btn>
      </confirm-block>
    </template>
    <div v-else class="home-card-cancellation__message">
      {{ $t('home.can_not_cancel') }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import type {
  DateTimeContainer,
  MyListingPropertyListItem,
} from '@homebourse/api-client'
import { getDynamicDateDiff, useMoment } from 'wue'
import { useApiHandler, useDateFormat, useDM } from '~/composables'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import ConfirmBlock from '~/modules/home/components/ConfirmBlock.vue'
import { useUserPropertyStore } from '~/stores'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'

export default defineComponent({
  name: 'HomeCardCancellation',
  components: { ConfirmBlock, HbBtn },
  props: {
    property: {
      type: Object as () => MyListingPropertyListItem,
      required: true,
    },
    cancellableUntil: {
      type: Object as () => DateTimeContainer,
      required: true,
    },
  },
  emits: ['canceled'],
  setup(props, ctx) {
    const moment = useMoment
    const now = moment()
    const showConfirm = ref(false)
    const diffLabel = computed(() => {
      const result = getDynamicDateDiff(props.cancellableUntil.date_time, now)
      return `${result.diff} ${result.format} left`
    })
    const formattedDate = computed(() =>
      useDateFormat(props.cancellableUntil.date_time)
    )
    const isCancellable = computed(
      () =>
        !props.cancellableUntil ||
        moment(props.cancellableUntil.date_time).isAfter(now)
    )
    const { api } = useUserPropertyStore()
    const { execute, pending } = useApiHandler(() =>
      api.acquisitionCancel({ id: props.property.id })
    )
    const emitCanceled = () => ctx.emit('canceled')
    const cancel = async () => {
      await execute(
        () => emitCanceled(),
        (error) => {
          if (error?.message) {
            useDM().open(FailureDialog, { message: error.message })
          }
        }
      )
      showConfirm.value = false
    }

    return {
      formattedDate,
      diffLabel,
      isCancellable,
      showConfirm,
      pending,
      cancel,
    }
  },
})
</script>

<style lang="scss">
.home-card-cancellation {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 18px;
  border-top: 1px solid var(--hb-gray2);

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: wrap;
    font-size: 10px;
  }

  &__message {
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
