<template>
  <div v-if="showActions" class="card-confirm-addition">
    <hb-btn
      size="sm"
      theme="dark"
      outline
      :disabled="confirmPending"
      :loading="declinePending"
      @click="decline()"
    >
      <span>{{ $t('label.decline') }}</span>
    </hb-btn>
    <hb-btn
      size="sm"
      :disabled="declinePending"
      :loading="confirmPending"
      @click="confirm()"
    >
      <span>{{ $t('label.confirm') }}</span>
    </hb-btn>
  </div>
</template>

<script lang="ts">
import type { MyListingPropertyListItem } from '@homebourse/api-client'
import { MyListingPropertyListItemStatusEnum } from '@homebourse/api-client'
import { computed } from 'vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import { useApiHandler, useDM } from '~/composables'
import { usePropertyStore } from '~/stores'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'

export default {
  name: 'CardConfirmAddition',
  components: { HbBtn },
  props: {
    property: {
      type: Object as () => MyListingPropertyListItem,
      required: true,
    },
  },
  emits: ['changed'],
  setup(props, ctx) {
    const showActions = computed(() =>
      [
        MyListingPropertyListItemStatusEnum.WaitingForApprove,
        MyListingPropertyListItemStatusEnum.Active,
      ].includes(props.property?.status)
    )
    const propertyStore = usePropertyStore()
    const emitChanged = () => ctx.emit('changed')
    const { pending: confirmPending, execute: callConfirm } = useApiHandler(
      () =>
        propertyStore.api.propertyOwnershipConfirm({ id: props.property.id })
    )
    const { pending: declinePending, execute: callDecline } = useApiHandler(
      () =>
        propertyStore.api.propertyOwnershipDecline({ id: props.property.id })
    )
    const confirm = () => {
      callConfirm(
        () => emitChanged(),
        (error) => {
          useDM().open(FailureDialog, { message: error?.message })
        }
      )
    }
    const decline = () => {
      callDecline(
        () => emitChanged(),
        (error) => {
          useDM().open(FailureDialog, { message: error?.message })
        }
      )
    }

    return {
      showActions,
      confirmPending,
      declinePending,
      confirm,
      decline,
    }
  },
}
</script>

<style lang="scss">
.card-confirm-addition {
  display: flex;
  gap: 10px;
  padding: 15px;
  border-top: 1px solid var(--hb-gray2);

  .hb-btn {
    flex: 1;
  }
}
</style>
