<template>
  <div :class="rootClasses">
    <div class="card-badge__row">
      <div
        v-if="!isBuyItNow() && !isSold"
        class="card-badge__label"
        :class="{
          'card-badge__label--icon':
            isCounterOffer() || (isOffer() && !isLoggedUsersOffer()),
        }"
      >
        <div
          v-if="isCounterOffer() || (isOffer() && !isLoggedUsersOffer())"
          class="card-badge__icon-badge-wrap"
        >
          <span
            v-if="property?.offer_count > 1 && !isLoggedUsersOffer()"
            class="card-badge__icon-badge"
          >
            {{ property?.offer_count }}
          </span>
          <nuxt-icon
            v-if="!isLoggedUsersOffer()"
            name="counter-offer"
            filled
          ></nuxt-icon>
        </div>
        {{
          isOffer()
            ? isLoggedUsersOffer()
              ? $t('offer_list.made_an_offer')
              : $t('offer_list.new_offer')
            : isLoggedUsersOffer()
            ? $t('offer_list.made_a_counteroffer')
            : $t('offer_list.received_a_counteroffer')
        }}
      </div>
      <template v-else-if="isSold">
        <div
          v-if="isLoggedUsersProperty()"
          class="card-badge__label card-badge__label--bought"
        >
          <nuxt-icon name="hammer" filled></nuxt-icon>
          {{ $t('offer_list.bought') }}
        </div>
        <div v-else class="card-badge__label card-badge__label--icon">
          <nuxt-icon name="sold" filled></nuxt-icon>
          {{ $t('offer_list.sold') }}
        </div>
      </template>

      <div class="card-badge__right">
        <div class="card-badge__price">
          {{ offer?.details?.amount?.formatted_price }}
        </div>
        <div v-if="!isBuyItNow() && remainingDays" class="card-badge__expire">
          {{ remainingDays }}
        </div>
        <div
          v-if="isLoggedUsersProperty() && property?.sold_at?.date_time"
          class="card-badge__date"
        >
          <date-format
            :date-time="property.sold_at.date_time"
            :show-time="false"
          />
        </div>
      </div>
    </div>
    <div v-if="showWaitingForConfirmation" class="card-badge__row">
      <div class="card-badge__text">
        <span>{{ $t('my_acquisitions.mls_waiting_for_confirmation') }}</span>
        <span
          v-tooltip="$t('my_acquisitions.mls_waiting_for_confirmation_info')"
        >
          <nuxt-icon name="info" filled />
        </span>
      </div>
    </div>
    <div v-if="isSold" class="card-badge__row">
      <div class="card-badge__text">
        <hb-raw-btn size="sm" @click="openAcceptedOfferDialog()">
          {{ $t('my_acquisitions.view_accepted_offer') }}
          <nuxt-icon name="chevron-right" filled />
        </hb-raw-btn>
      </div>
    </div>
    <div v-if="!!offer && !isSold" class="card-badge__row">
      <hb-btn size="block" @click="emitOpenOfferList()">
        <span>
          {{ $t('home.view_offer', property.offer_count) }}
        </span>
      </hb-btn>
    </div>
  </div>
</template>

<script lang="ts">
import type { MyListingPropertyListItem, Offer } from '@homebourse/api-client'
import {
  MyListingPropertyListItemStatusEnum,
  OfferStatusEnum,
  OfferTypeEnum,
} from '@homebourse/api-client'
import { computed } from 'vue'
import { useMoment } from 'wue'
import { useOfferTools } from '~/modules/home/composables/offer'
import DateFormat from '~/components/DateFormat.vue'
import { useDM, useI18n } from '#imports'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'
import ViewAcceptedOfferDialog from '~/modules/home/components/dialogs/ViewAcceptedOfferDialog.vue'

export default {
  name: 'CardBadge',
  components: { HbRawBtn, HbBtn, DateFormat },
  props: {
    offer: { type: Object as () => Offer, required: true },
    property: {
      type: Object as () => MyListingPropertyListItem,
      required: true,
    },
  },
  emits: ['open-offer-list'],
  setup(props, ctx) {
    const { t } = useI18n()
    const tools = useOfferTools(props.offer, props.property)
    const isSold = computed(
      () => props.property?.status === MyListingPropertyListItemStatusEnum.Sold
    )
    const rootClasses = computed(() => ({
      'card-badge': true,
      'card-badge--top': true,
      'card-badge--more': props.property?.offer_count > 1 && !isSold.value,
      'card-badge--secondary': isSold.value,
    }))
    const remainingDays = computed(() => {
      if (!props.offer || isSold.value) {
        return null
      }

      const now = useMoment()
      const expire = useMoment(props.offer?.expiration_at?.date_time)
      const diff = expire.diff(now, 'days')

      return diff <= 0 ? null : diff + ` ${t('home.days')}`
    })
    const showWaitingForConfirmation = computed(
      () =>
        props.offer?.status === OfferStatusEnum.WaitingForConfirmation &&
        props.offer?.type === OfferTypeEnum.MlsBuyItNow
    )
    const emitOpenOfferList = () => ctx.emit('open-offer-list')
    const openAcceptedOfferDialog = () => {
      useDM().open(ViewAcceptedOfferDialog, {
        offer: props.offer,
        property: props.property,
      })
    }

    return {
      rootClasses,
      isSold,
      remainingDays,
      showWaitingForConfirmation,
      emitOpenOfferList,
      openAcceptedOfferDialog,
      ...tools,
    }
  },
}
</script>

<style lang="scss">
.card-badge {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  z-index: 1;
  padding: 13px 13px 13px 18px;

  &--top {
    top: 5px;
    left: 5px;
    right: 5px;
  }

  &--more {
    &:before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 10px;
      right: 10px;
      width: auto;
      background-color: var(--hb-gray2);
      height: 10px;
      z-index: -1;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &__icon-badge-wrap {
    position: relative;
  }

  &__icon-badge {
    position: absolute;
    top: -9px;
    left: -9px;
    color: #fff;
    height: 19px;
    width: 19px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    background-color: var(--hb-primary);
  }

  &--secondary {
    background-color: var(--hb-secondary);
    color: #fff;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & + & {
      margin-top: 12px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    flex: 0 0 57%;

    .nuxt-icon {
      display: block;
    }

    &--bought {
      font-size: 16px;

      .nuxt-icon {
        margin-right: 12px;
        font-size: 20px;
      }
    }

    &--icon {
      font-size: 14px;

      .nuxt-icon {
        margin-right: 12px;
        font-size: 20px;
      }
    }
  }

  &__text {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 14px;

    button {
      color: var(--hb-white);
      text-decoration: none;
      display: flex;
      align-items: center;

      .nuxt-icon {
        font-size: 11px;
        line-height: 14px;
        margin-left: 4px;
        transition: margin-left 0.3s ease;
        will-change: margin-left;
      }

      &:hover {
        .nuxt-icon {
          margin-left: 8px;
        }
      }
    }

    > span {
      white-space: normal;
    }

    .nuxt-icon {
      font-size: 17px;
    }
  }

  &__price {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  &__expire {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
  }

  &__date {
    font-size: 12px;
    font-weight: 500;
    text-align: right;
  }
}
</style>
