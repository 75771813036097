<template>
  <div :class="rootClasses" v-on="rootListeners">
    <div class="home-card__image-wrapper">
      <div
        class="home-card__ratio"
        :class="{
          'home-card__ratio--decline':
            (soldIsGray && isSold) || isInactive || isDeclined,
        }"
      >
        <client-only>
          <card-badge
            v-if="offer"
            :offer="offer"
            :property="property"
            @open-offer-list="emitOpenOfferList()"
          ></card-badge>
          <home-card-carousel
            v-if="
              showSliderOnHover
                ? showCarousel
                : optionalVisibleCarousel
                ? showCarousel
                : true
            "
            v-model="activeCarouselIndex"
            class="home-card__carousel"
            :img-src-list="property.images"
            :home-url="homeUrl"
            :loaded-from="loadedFrom"
            :disable-slide-on-mobile="disableSlideOnMobile"
            :enable-preload="allowedPreload"
            @ready="onCarouselReady()"
          />
          <hb-image
            v-show="showSliderOnHover ? !carouselReady : false"
            class="home-card__placeholder-image"
            :image="placeholderImage"
          />
        </client-only>
        <hb-btn
          v-if="property?.renewable && renewable"
          size="sm"
          class="home-card__decline-btn"
          :loading="renewalPending"
          @click="renewal()"
        >
          <span>{{ $t('label.renew') }}</span>
        </hb-btn>
      </div>
      <div class="home-card__favorite">
        <home-save-btn
          :home-id="property?.id"
          :is-saved="!!property?.liked"
          :undoable-dislike="undoableDislike"
          @saved="emitSaved()"
          @unsaved="emitUnsaved()"
        />
      </div>
    </div>
    <a
      class="home-card__link-container"
      :href="homeUrl"
      :target="linkTarget"
      @click="onLinkClick"
    >
      <div class="home-card__middle">
        <div class="home-card__price">
          {{ property?.price.formatted_price }}
          <span
            v-if="property?.media_options?.inspected_real_estate"
            v-tooltip="$t('home.supervised_real_estates_tooltip')"
            class="home-card__price-icon"
          >
            <nuxt-icon name="supervise" filled></nuxt-icon>
          </span>
          <span
            v-if="property?.instant_buy"
            v-tooltip="$t('home.instant_buy_description')"
            :data-title="$t('home.instant_buying')"
            class="home-card__price-icon"
          >
            <nuxt-icon name="flash" filled></nuxt-icon>
          </span>
          <span
            v-if="property?.accept_crypto"
            v-tooltip="$t('home.seller_accepts_crypto_tooltip')"
            class="home-card__price-icon"
          >
            <nuxt-icon name="crypto" filled></nuxt-icon>
          </span>
        </div>
        <div
          v-if="loadedFrom === 'project' && property?.name"
          class="home-card__name"
        >
          {{ property?.name }}
        </div>
        <div v-else class="home-card__location">
          {{ property?.address.full_address }}
        </div>

        <!-- new feature -->
        <!--<div class="home-card__owner">-->
        <!--  <img-->
        <!--    src="/images/placeholder.png"-->
        <!--    alt=""-->
        <!--    class="home-card__owner-image"-->
        <!--  />-->
        <!--  <span>Patrick James</span>-->
        <!--</div>-->
        <!-- <div
          v-if="
            (property?.owner_invitation_status && !ownerConfirm) || invitable
          "
          class="home-card__owner-status"
          @click.prevent.stop="openResendInvitation()"
        >
          <div class="home-card__owner-status-wrap">
            <span class="home-card__owner-status-text">
              {{
                invitable
                  ? $t('home.invite_owner')
                  : ownerInvitationIsPending || ownerInvitationIsDenied
                  ? $t('home.owners_status')
                  : $t('label.owner')
              }}
            </span>
            <span
              class="home-card__owner-status-badge"
              :class="{ 'bg-danger text-white': ownerInvitationIsDenied }"
            >
              {{
                invitable
                  ? ownerInvitationIsPending
                    ? $t('label.pending')
                    : $t('label.invite')
                  : ownerInvitationIsPending || ownerInvitationIsDenied
                  ? ownerInvitationIsDenied
                    ? $t('label.denied')
                    : $t('label.pending')
                  : property?.owner?.name
              }}
            </span>
          </div>
          <nuxt-icon
            v-if="ownerInvitationIsPending"
            name="chevron-right"
            filled
          ></nuxt-icon>
        </div> -->
        <div class="home-card__agency">
          <nuxt-icon name="check" filled /> {{ $t('label.free_cancellation') }}
        </div>
      </div>
      <div class="home-card__bottom">
        <div class="home-card__bottom-item">
          <nuxt-icon name="bedroom" filled></nuxt-icon>
          <span>{{ property?.property_card_properties?.bedrooms }}</span>
        </div>
        <div class="home-card__bottom-item">
          <nuxt-icon name="bathroom" filled></nuxt-icon>
          <span>{{ property?.property_card_properties?.bathrooms }}</span>
        </div>
        <div class="home-card__bottom-item">
          <span>
            {{ property?.property_card_properties?.size?.toLocaleString() }}
          </span>
          &nbsp;sqft
        </div>
      </div>
      <card-saved-home-addition
        v-if="highlightAddition && property?.highlights"
        :highlights="property?.highlights"
      />
    </a>
    <card-profile-list-addition
      v-if="bottomAddition"
      :property="property"
      @deleted="emitDeleted()"
    ></card-profile-list-addition>
    <card-decline-addition
      v-if="property?.declined_description"
      :property="property"
    ></card-decline-addition>
    <card-confirm-addition
      v-if="ownerConfirm && ownerInvitationIsPending"
      :property="property"
      @changed="emitOwnerChanged()"
    ></card-confirm-addition>
    <home-card-cancellation
      v-if="(cancellable || offer?.cancellable_until) && !disabledCancel"
      :property="property"
      :cancellable-until="offer.cancellable_until"
      @canceled="emitCanceled()"
    />
  </div>
</template>

<script lang="ts">
import type {
  MyListingPropertyListItem,
  Offer,
  PropertyListItem,
  SrcSet,
} from '@homebourse/api-client'
import {
  MyListingPropertyListItemOwnerInvitationStatusEnum,
  PropertyStatusEnum,
} from '@homebourse/api-client'
import { computed, defineComponent, ref } from 'vue'
import { useRoute, useRouter, useRuntimeConfig } from '#app'
import HomeCardCarousel from '~/modules/home/components/carousels/HomeCardCarousel.vue'
import HomeSaveBtn from '~/modules/home/components/home-page/HomeSaveBtn.vue'
import CardBadge from '~/components/CardBadge.vue'
import CardConfirmAddition from '~/components/CardConfirmAddition.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import CardProfileListAddition from '~/components/CardProfileListAddition.vue'
import CardDeclineAddition from '~/components/CardDeclineAddition.vue'
import { usePropertyStore } from '~/stores'
import { useApiHandler, useDM, useNuxtData } from '~/composables'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'
import ReSendOwnerInvitationDialog from '~/modules/home/components/dialogs/ReSendOwnerInvitationDialog.vue'
import { listItemToProperty } from '~/modules/home/utils/property-tools'
import HbImage from '~/components/base/HbImage.vue'
import { HOME_PLACEHOLDER_SRC_SET_ITEM } from '~/modules/home/constants'
import CardSavedHomeAddition from '~/components/CardSavedHomeAddition.vue'
import HomeCardCancellation from '~/components/HomeCardCancellation.vue'

export default defineComponent({
  name: 'HomeCard',
  components: {
    HomeCardCancellation,
    CardSavedHomeAddition,
    HbImage,
    CardDeclineAddition,
    CardProfileListAddition,
    CardBadge,
    HomeSaveBtn,
    HomeCardCarousel,
    CardConfirmAddition,
    HbBtn,
  },
  props: {
    property: {
      type: Object as () => PropertyListItem & MyListingPropertyListItem,
      required: true,
    },
    noShadow: { type: Boolean, default: false },
    fixedShadow: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    ownerConfirm: { type: Boolean, default: false },
    bottomAddition: { type: Boolean, default: false },
    highlightAddition: { type: Boolean, default: false },
    soldIsGray: { type: Boolean, default: false },
    offer: { type: Object as () => Offer, default: () => null },
    disableSlideOnMobile: { type: Boolean, default: false },
    showSliderOnHover: { type: Boolean, default: () => false },
    undoableDislike: { type: Boolean, default: () => false },
    enabledPreload: { type: Boolean, default: () => true },
    renewable: { type: Boolean, default: () => false },
    optionalVisibleCarousel: { type: Boolean, default: () => false },
    cancellable: { type: Boolean, default: () => false },
    disabledCancel: { type: Boolean, default: () => false },
    invitable: { type: Boolean, default: () => false },
    loadedFrom: { type: String, default: () => '' },
    projectSlug: { type: String, default: () => '' },
  },
  emits: [
    'open-offer-list',
    'deleted',
    'owner-changed',
    'renewed',
    'mouse-enter',
    'mouse-leave',
    'saved',
    'unsaved',
    'canceled',
  ],
  setup(props, ctx) {
    const router = useRouter()
    const route = useRoute()
    const showCarousel = ref(false)
    const carouselReady = ref(false)
    const carouselReadyTimer = ref(null)
    const activeCarouselIndex = ref(0)
    const propertyStore = usePropertyStore()
    const allowedPreload = ref<boolean>(props.enabledPreload)
    const placeholderItem = ref<SrcSet>({
      data: [HOME_PLACEHOLDER_SRC_SET_ITEM],
    })
    const placeholderImage = computed(() => {
      return (
        props.property?.images?.[activeCarouselIndex.value] ||
        placeholderItem.value
      )
    })
    const rootClasses = computed(() => ({
      'home-card': true,
      'home-card--no-shadow': props.noShadow,
      'home-card--fixed-shadow': props.fixedShadow,
      'home-card--small': props.isSmall,
      'home-card--block': props.block,
    }))
    const emitMouseEnter = () => ctx.emit('mouse-enter')
    const emitMouseLeave = () => ctx.emit('mouse-leave')
    const rootListeners = {
      mouseenter: () => {
        emitMouseEnter()

        if (props.showSliderOnHover) {
          showCarousel.value = true
        }
      },
      mouseleave: () => {
        emitMouseLeave()

        if (props.showSliderOnHover) {
          clearTimeout(carouselReadyTimer.value)
          carouselReady.value = false
          showCarousel.value = false
        }
      },
    }
    const config = useRuntimeConfig()
    const baseUrl = config.public.baseUrl

    const homeUrl = computed(() => {
      if (props.loadedFrom === 'project' && props.projectSlug !== '') {
        return `${baseUrl.replace('://', `://${props.projectSlug}.`)}${
          props.property ? `/home/${props.property.slug}` : '/home'
        }`
      }
      return props.property ? `/home/${props.property.slug}` : '/home'
    })
    const linkTarget = computed(() => {
      if (props.loadedFrom === 'project') return '_blank'
      return route?.meta?.mustAuth ? '_blank' : '_self'
    })
    const isSold = computed(
      () => props.property?.status === PropertyStatusEnum.Sold
    )
    const isInactive = computed(
      () => props.property?.status === PropertyStatusEnum.Inactive
    )
    const isDeclined = computed(
      () => props.property?.status === PropertyStatusEnum.Declined
    )
    const ownerInvitationIsPending = computed(() =>
      [
        MyListingPropertyListItemOwnerInvitationStatusEnum.Invited,
        MyListingPropertyListItemOwnerInvitationStatusEnum.WaitingForConfirmation,
      ].includes(props.property?.owner_invitation_status)
    )
    const ownerInvitationIsDenied = computed(
      () =>
        props.property?.owner_invitation_status ===
        MyListingPropertyListItemOwnerInvitationStatusEnum.Denied
    )

    const emitOpenOfferList = () => ctx.emit('open-offer-list')
    const emitDeleted = () => ctx.emit('deleted')
    const emitRenewed = () => ctx.emit('renewed')
    const emitCanceled = () => ctx.emit('canceled')
    const emitOwnerChanged = () => ctx.emit('owner-changed')
    const { pending: renewalPending, execute: callRenewal } = useApiHandler(
      () => propertyStore.api.listingRenewal({ id: props.property?.id })
    )
    const emitSaved = () => ctx.emit('saved')
    const emitUnsaved = () => ctx.emit('unsaved')
    const renewal = () => {
      callRenewal(
        () => emitRenewed(),
        (error) => {
          useDM().open(FailureDialog, { message: error?.message })
        }
      )
    }
    const openResendInvitation = () => {
      if (!ownerInvitationIsPending.value && !props.invitable) {
        return
      }

      useDM().open(ReSendOwnerInvitationDialog, {
        property: props.property,
        isResend: !props.invitable,
        afterClose(isSuccess: boolean) {
          if (isSuccess) {
            emitOwnerChanged()
          }
        },
      })
    }
    const onLinkClick = (event: MouseEvent) => {
      if (!route?.meta?.mustAuth) {
        event.preventDefault()
        event.stopPropagation()

        useNuxtData(props.property.slug).value = listItemToProperty(
          props.property
        )

        if (props.loadedFrom === 'project') {
          let openUrl = homeUrl.value
          if (props.projectSlug) {
            openUrl = `${baseUrl.replace('://', `://${props.projectSlug}.`)}${
              homeUrl.value
            }`
          }
          // open in new tab if on project page
          window.open(openUrl, '_blank')
        } else {
          router.push({
            path: homeUrl.value,
          })
        }
      }
    }
    const onCarouselReady = () => {
      carouselReadyTimer.value = setTimeout(() => {
        carouselReady.value = true
      }, 100)
    }
    const enableCarousel = () => (showCarousel.value = true)
    const disableCarousel = () => (showCarousel.value = false)
    const enablePreload = () => (allowedPreload.value = true)
    const disablePreload = () => (allowedPreload.value = false)

    return {
      homeUrl,
      linkTarget,
      rootClasses,
      rootListeners,
      isSold,
      isInactive,
      isDeclined,
      renewalPending,
      ownerInvitationIsPending,
      ownerInvitationIsDenied,
      showCarousel,
      carouselReady,
      placeholderItem,
      placeholderImage,
      activeCarouselIndex,
      allowedPreload,
      renewal,
      onLinkClick,
      openResendInvitation,
      emitOpenOfferList,
      emitDeleted,
      emitOwnerChanged,
      onCarouselReady,
      enableCarousel,
      enablePreload,
      disablePreload,
      emitSaved,
      emitUnsaved,
      emitCanceled,
      disableCarousel,
    }
  },
})
</script>

<style lang="scss">
.home-card {
  border: 1px solid var(--hb-gray1);
  border-radius: 10px;
  background: var(--hb-white);
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  color: inherit;

  @include tablet {
    border: 2px solid var(--hb-gray2);
  }

  &__carousel {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
  }

  &__placeholder-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
  }

  &__favorite {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  @media (min-width: 768px) {
    &:hover {
      position: relative;
      z-index: 1;
      box-shadow: 30px 20px 99px #1d323d4d;
      border-color: #fff;
    }
  }

  &__image-wrapper {
    position: relative;
    padding: 15px 15px 0 15px;
  }

  &__ratio {
    position: relative;
    border-radius: 5px;
    padding-top: 66.66%;
    background-color: #eee;

    &--decline {
      img {
        filter: grayscale(100%);
      }
    }
  }

  &__decline-btn {
    position: absolute;
    bottom: 23px;
    left: 60px;
    right: 60px;
    width: auto;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  &__link-container {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    height: 100%;
  }

  &__middle {
    padding: 20px 15px 20px 15px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include minimized-default-home-card {
      padding: 11px 15px 15px;
    }
  }

  &__price {
    margin-bottom: 12px;
    font-size: 26px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 6px;

    @include minimized-default-home-card {
      margin-bottom: 6px;
    }
  }

  &__price-icon {
    line-height: 0;

    .nuxt-icon {
      font-size: 20px;
    }
  }

  &__name {
    margin-bottom: 26px;
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include minimized-default-home-card {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  &__location {
    margin-bottom: 26px;
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include minimized-default-home-card {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  &__agency {
    margin-top: auto;
    font-size: 11px;
    font-weight: 500;
    color: #98a5ab;

    .nuxt-icon {
      margin-right: 8px;
    }
  }

  &__owner {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    span {
      font-size: 11px;
      opacity: 0.5;
    }
  }

  &__owner-image {
    width: 27px;
    min-width: 27px;
    height: 27px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50%;
  }

  &__owner-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 18px 8px 15px;
    border: 1px solid var(--hb-gray2);
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 10px;

    .nuxt-icon {
      font-size: 10px;
    }
  }

  &__owner-status-text {
    font-size: 11px;
    font-weight: 600;
  }

  &__owner-status-wrap {
    display: flex;
    align-items: center;
    gap: 13px;
  }

  &__owner-status-badge {
    font-size: 10px;
    font-weight: 600;
    background-color: var(--hb-gray2);
    border-radius: 5px;
    padding: 4px 8px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    border-top: 1px solid var(--hb-gray2);
  }

  &__bottom-item {
    padding: 15px 17px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    height: 100%;

    &:nth-of-type(2) {
      border-right: 1px solid var(--hb-gray2);
      border-left: 1px solid var(--hb-gray2);
    }

    &:nth-of-type(3) {
      font-size: 15px;
      font-weight: 500;
      align-items: flex-end;
      flex-wrap: wrap;

      span {
        font-weight: 700;
      }
    }

    span {
      font-size: 22px;
      font-weight: 700;
      line-height: 1;
    }

    .nuxt-icon {
      margin-right: 9px;
      font-size: 17px;
      color: var(--hb-primary);
    }
  }

  &--no-shadow {
    &:hover {
      box-shadow: unset;
      border-color: var(--hb-gray1);
    }
  }

  &--fixed-shadow {
    box-shadow: 30px 20px 99px #1d323d4d;
    border-color: #fff;
  }

  &--small {
    .home-card__price {
      font-size: 23px;
    }

    .home-card__location {
      font-size: 12px;
    }

    .home-card__agency {
      font-size: 10px;
    }

    .home-card__bottom-item {
      font-size: 13px;
      padding: 15px;

      .nuxt-icon {
        font-size: 15px;
      }

      span {
        font-size: 19px;
      }
    }

    @include minimized-search-home-card {
      .home-card__price {
        font-size: 22px;
      }

      .home-card__ratio {
        padding-top: 60%;
      }

      .home-card__bottom {
        border-top: 0;
        padding: 0 15px 15px;
        justify-content: flex-start;
        gap: 20px;
      }

      .home-card__bottom-item {
        border: 0;
        flex: 0 1 auto;
        padding: 0;
        justify-content: flex-start;
      }
    }
  }

  &--block {
    max-width: 100%;
    width: 100%;
  }

  &.search-map__selected-property {
    @include mobile {
      .home-card__image-wrapper {
        width: 150px;
        min-width: 150px;
        padding-bottom: 15px;
      }

      .home-card__middle {
        padding-left: 0;
      }

      .home-card__ratio {
        padding-top: 100%;
      }

      .home-card__bottom {
        margin-top: 0;
        border: 0;
        align-items: flex-end;
        flex-wrap: wrap;
        padding-left: 0;
      }

      .home-card__location {
        margin-bottom: 0;
      }

      .home-card__agency {
        margin-top: 8px;
      }

      .home-card__bottom-item {
        border: 0 !important;
        justify-content: flex-start;
        padding: 0 0 10px 0;
        align-items: center;
        text-align: left;
      }
    }
  }
}
</style>
