<template>
  <div class="card-decline-addition">
    <nuxt-icon name="info" filled></nuxt-icon>
    <div class="card-decline-addition__text">
      {{ property?.declined_description }}
    </div>
  </div>
</template>

<script lang="ts">
import type { MyListingPropertyListItem } from '@homebourse/api-client'

export default {
  name: 'CardDeclineAddition',
  props: {
    property: {
      type: Object as () => MyListingPropertyListItem,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.card-decline-addition {
  display: flex;
  gap: 16px;
  padding: 24px 20px 30px;
  border-top: 1px solid var(--hb-gray2);

  .nuxt-icon {
    font-size: 22px;
    color: var(--hb-primary);
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
