<template>
  <hb-dialog size="sm">
    <hb-dialog-header>
      {{
        $t(
          isResend
            ? 'home.re_send_owner_invitation'
            : 'home.send_owner_invitation'
        )
      }}
    </hb-dialog-header>

    <hb-form
      v-model="model"
      :errors="errorBody?.errors || {}"
      :disabled="pending"
      no-style
    >
      <hb-dialog-body>
        <hb-input
          type="email"
          name="email"
          :placeholder="$t('home.owner_email_short')"
        />
      </hb-dialog-body>

      <hb-dialog-footer>
        <hb-dialog-actions>
          <hb-btn
            outline
            theme="light"
            size="block-lg"
            type="button"
            :disabled="pending"
            @click="dialog.close(afterClose)"
          >
            <span>{{ $t('label.cancel') }}</span>
          </hb-btn>
          <hb-btn
            theme="secondary"
            size="block-lg"
            :loading="pending"
            @click="reSendInvitation()"
          >
            <span>{{ $t('label.send') }}</span>
          </hb-btn>
        </hb-dialog-actions>
      </hb-dialog-footer>
    </hb-form>
  </hb-dialog>
</template>

<script lang="ts">
import type {
  MyListingPropertyListItem,
  OwnerInvitation,
} from '@homebourse/api-client'
import { ref } from 'vue'
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbDialogActions from '~/components/base/dialog/HbDialogActions.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbForm from '~/components/base/form/HbForm.vue'
import HbInput from '~/components/base/form/HbInput.vue'
import { useApiHandler, useDialog, useDM } from '~/composables'
import { DialogMixin } from '~/mixins'
import { useUserPropertyStore } from '~/stores'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'

export default {
  name: 'ReSendOwnerInvitationDialog',
  components: {
    HbInput,
    HbForm,
    HbBtn,
    HbDialogActions,
    HbDialogFooter,
    HbDialogBody,
    HbDialogHeader,
    HbDialog,
  },
  mixins: [DialogMixin],
  props: {
    property: {
      type: Object as () => MyListingPropertyListItem,
      required: true,
    },
    isResend: { type: Boolean, default: () => false },
  },
  setup(props) {
    const dialog = useDialog(props)
    const model = ref<OwnerInvitation>({ email: null })
    const userPropertyStore = useUserPropertyStore()
    const { pending, errorBody, execute } = useApiHandler(() =>
      userPropertyStore.api.ownerInvitation({
        id: props.property?.id,
        ownerInvitation: model.value,
      })
    )
    const reSendInvitation = () => {
      execute(
        () => dialog.value.close(() => props.afterClose(true)),
        (error) => {
          if (!error?.hasFormError) {
            useDM().open(FailureDialog, { message: error.message })
          }
        }
      )
    }

    return {
      dialog,
      model,
      pending,
      errorBody,
      reSendInvitation,
    }
  },
}
</script>

<style scoped></style>
