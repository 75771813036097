<template>
  <div
    v-if="isActive || (isSold && property?.sold_at) || isDraft || isDeclined"
    class="card-profile-list-addition"
  >
    <div class="card-profile-list-addition__left">
      <div v-if="!isSold && property?.added_at && !isDraft">
        <span>{{ $t('label.added') }}</span>
        {{ format(property?.added_at?.date_time) }}
      </div>
      <div v-if="isSold && property?.sold_at && !isDraft">
        <span>{{ $t('label.sold') }}</span>
        {{ format(property?.sold_at?.date_time) }}
      </div>
      <div v-if="!isSold && property?.expiration_at && !isDraft">
        <span>{{ $t('label.expiration') }}:</span>
        {{ format(property?.expiration_at?.date_time) }}
      </div>
    </div>
    <div class="card-profile-list-addition__right">
      <spinner v-if="pending" />
      <template v-else>
        <nuxt-link
          v-if="property?.editable"
          :to="`/home/upload/${property.id}`"
        >
          <nuxt-icon name="edit" filled></nuxt-icon>
        </nuxt-link>
        <hb-raw-btn v-if="property?.deletable" @click="deleteHandler()">
          <nuxt-icon name="trash" filled></nuxt-icon>
        </hb-raw-btn>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import type { MyListingPropertyListItem } from '@homebourse/api-client'
import {
  MyListingPropertyListItemStatusEnum,
  MyListingSearchStatusEnum,
  PropertyStatusEnum,
} from '@homebourse/api-client'
import { computed } from 'vue'
import { useApiHandler, useDateFormat, useDM } from '~/composables'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'
import { useI18n, usePropertyStore, useUserStore } from '#imports'
import ConfirmDialog from '~/components/dialogs/ConfirmDialog.vue'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'
import Spinner from '~/components/loaders/Spinner.vue'

export default {
  name: 'CardProfileListAddition',
  components: { Spinner, HbRawBtn },
  props: {
    property: {
      type: Object as () => MyListingPropertyListItem,
      required: true,
    },
  },
  emits: ['deleted'],
  setup(props, ctx) {
    const { t } = useI18n()
    const { open: openDialog } = useDM()
    const propertyStore = usePropertyStore()
    const userStore = useUserStore()
    const { pending, execute: deleteProperty } = useApiHandler(() =>
      propertyStore.api.propertyDelete({ id: props.property?.id })
    )
    const isActive = computed(
      () =>
        props.property?.status === MyListingPropertyListItemStatusEnum.Active
    )
    const isSold = computed(
      () => props.property?.status === MyListingPropertyListItemStatusEnum.Sold
    )
    const isDraft = computed(
      () => props.property?.status === MyListingPropertyListItemStatusEnum.Draft
    )
    const isDeclined = computed(
      () => props.property?.status === MyListingSearchStatusEnum.Declined
    )
    const emitDeleted = () => ctx.emit('deleted')
    const deleteHandler = () => {
      openDialog(ConfirmDialog, {
        title: t('home.delete_property_confirm_title'),
        message: t('home.delete_property_confirm_message'),
        btnLabel: t('label.delete'),
        async onAccept() {
          await deleteProperty(
            () => {
              if (props.property?.status === PropertyStatusEnum.Active) {
                userStore.user.active_my_listing_count--
              }
              emitDeleted()
            },
            (error) => {
              openDialog(FailureDialog, { message: error.message })
            }
          )
        },
      })
    }

    return {
      format: useDateFormat,
      pending,
      isActive,
      isSold,
      isDraft,
      isDeclined,
      deleteHandler,
    }
  },
}
</script>

<style lang="scss">
.card-profile-list-addition {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--hb-gray2);
  padding: 15px 20px;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 3px;
    font-size: 10px;

    span {
      font-weight: 600;
    }
  }

  &__right {
    display: flex;
    gap: 15px;

    .nuxt-icon {
      font-size: 18px;
    }
  }
}
</style>
