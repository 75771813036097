<template>
  <div class="card-saved-home-addition">
    <ul>
      <li v-if="highlights?.type">
        <nuxt-icon name="home" filled></nuxt-icon>
        {{ $t(`home.type_${highlights.type}`) }}
      </li>

      <li v-if="highlights?.year_built">
        <nuxt-icon name="highlight/key" filled></nuxt-icon>
        {{
          $t('home.highlights_year_built', {
            year_built: highlights.year_built,
          })
        }}
      </li>

      <!--<li v-if="highlights?.agency_fee">-->
      <!--  <nuxt-icon name="highlight/percent" filled></nuxt-icon>-->
      <!--  {{-->
      <!--    $t('home.highlights_agency_fee', {-->
      <!--      agency_fee: highlights.agency_fee,-->
      <!--    })-->
      <!--  }}-->
      <!--</li>-->

      <li v-if="highlights?.heating?.length && heatingAsString">
        <nuxt-icon name="highlight/temperature" filled></nuxt-icon>
        {{ heatingAsString }}
      </li>

      <li v-if="highlights?.cooling?.length && coolingAsString">
        <nuxt-icon name="highlight/automatic_ac" filled></nuxt-icon>
        {{ coolingAsString }}
      </li>

      <li v-if="highlights?.parking">
        <nuxt-icon name="highlight/parking" filled></nuxt-icon>
        {{ $t('home.highlights_parking', highlights?.parking ?? 0) }}
      </li>

      <li v-if="highlights?.hoa_fee?.formatted_price">
        <nuxt-icon name="highlight/invoice" filled></nuxt-icon>
        {{
          $t('home.highlights_hoa_fee', {
            hoa_fee: highlights.hoa_fee.formatted_price,
          })
        }}
      </li>

      <li v-if="highlights?.sqft_price?.formatted_price">
        <nuxt-icon name="highlight/money_amount" filled></nuxt-icon>
        {{
          $t('home.highlights_sqft_price', {
            sqft_price: highlights.sqft_price.formatted_price,
          })
        }}
      </li>

      <li v-if="highlights?.acres">
        <nuxt-icon name="highlight/other" filled></nuxt-icon>
        {{ $t('home.highlights_acres', { acres: highlights.acres }) }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import type { PropertyHighlights } from '@homebourse/api-client'
import { computed } from 'vue'
import { useI18n } from '#imports'

export default {
  name: 'CardSavedHomeAddition',
  props: {
    highlights: {
      type: Object as () => PropertyHighlights,
      default: () => ({}),
    },
  },
  setup(props) {
    const { t } = useI18n()
    const translateHighlightListTypes = (mainKey: string) => {
      const translates: string[] = (props.highlights?.[mainKey] || []).map(
        (subKey) => t(`home.${mainKey}_${subKey}`)
      )

      if (!translates.length) {
        return null
      }

      if (translates.length > 1) {
        return `${translates[0]} (+${translates.length - 1})`
      }

      return translates.join(', ')
    }
    const heatingAsString = computed(() =>
      translateHighlightListTypes('heating')
    )
    const coolingAsString = computed(() =>
      translateHighlightListTypes('cooling')
    )

    return {
      heatingAsString,
      coolingAsString,
    }
  },
}
</script>

<style lang="scss">
.card-saved-home-addition {
  padding: 22px 20px 25px;
  border-top: 1px solid var(--hb-gray2);

  ul {
    list-style: none;

    li {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .nuxt-icon {
        font-size: 20px;
        margin-right: 15px;

        &,
        svg {
          color: var(--hb-gray4);
          fill: var(--hb-gray4);
        }
      }
    }
  }
}
</style>
