<template>
  <hb-dialog size="sm">
    <hb-dialog-header>
      {{ $t('my_acquisitions.accepted_offer') }}
    </hb-dialog-header>

    <hb-dialog-body>
      <hb-form-group :label="$t('home.total_amount')">
        <hb-form-group-col>
          <strong>{{ offer.details.amount.formatted_price }}</strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group
        v-if="isDefined(property?.property_sales_conditions?.down_payment)"
        :label="$t('home.down_payment')"
      >
        <hb-form-group-col>
          <strong>
            {{ property?.property_sales_conditions?.down_payment }}
            {{ $t('label.days').toLowerCase() }}
          </strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group
        v-if="
          isDefined(property?.property_sales_conditions?.down_payment_deadline)
        "
        :label="$t('home.down_payment_deadline')"
      >
        <hb-form-group-col>
          <strong>
            {{ property?.property_sales_conditions?.down_payment_deadline }}
            {{ $t('label.days').toLowerCase() }}
          </strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group :label="$t('home.payment_deadline')">
        <hb-form-group-col>
          <strong>
            {{ offer.details.payment_deadline }}
            {{ $t('label.days').toLowerCase() }}
          </strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group
        v-if="
          isDefined(property?.property_sales_conditions?.cancellation_deadline)
        "
        :label="$t('home.cancellation_deadline')"
      >
        <hb-form-group-col>
          <strong>
            {{ property?.property_sales_conditions?.cancellation_deadline }}
            {{ $t('label.days').toLowerCase() }}
          </strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group :label="$t('label.expiration')">
        <hb-form-group-col>
          <strong>
            <date-format :date-time="offer.expiration_at.date_time" />
          </strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group :label="$t('label.type')">
        <hb-form-group-col>
          <strong>
            {{ $t(`acquisition.offer_type_${offer.type}`) }}
          </strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group
        v-if="offer.details.message"
        :label="$t('home.additional_condition')"
      >
        <hb-form-group-col>
          <strong>
            {{ offer.details.message }}
          </strong>
        </hb-form-group-col>
      </hb-form-group>

      <hb-form-group
        v-if="offer.from_user?.name"
        :label="$t('offer_list.from')"
      >
        <hb-form-group-col>
          <div class="offer__from-user">
            <hb-image
              v-if="offer.from_user?.image"
              class="offer__from-user__image"
              :image="offer.from_user?.image"
            />
            <name-initials-image
              v-else
              class="offer__from-user__image"
              :name="offer.from_user?.name"
            />
            <div class="offer__from-user__name">
              {{ offer.from_user?.name }}
            </div>
          </div>
        </hb-form-group-col>
      </hb-form-group>
    </hb-dialog-body>

    <hb-dialog-footer no-border>
      <hb-dialog-actions>
        <hb-btn
          outline
          theme="light"
          size="lg"
          type="button"
          @click="dialog.close()"
        >
          <span>{{ $t('label.cancel') }}</span>
        </hb-btn>
      </hb-dialog-actions>
    </hb-dialog-footer>
  </hb-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Offer, PropertyListItem } from '@homebourse/api-client'
import { isUndefined } from 'wue'
import { DialogMixin } from '~/mixins'
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbDialogActions from '~/components/base/dialog/HbDialogActions.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbFormGroup from '~/components/base/form/layout/HbFormGroup.vue'
import HbFormGroupCol from '~/components/base/form/layout/HbFormGroupCol.vue'
import { useDialog } from '~/composables'
import DateFormat from '~/components/DateFormat.vue'
import NameInitialsImage from '~/modules/auth/components/NameInitialsImage.vue'
import HbImage from '~/components/base/HbImage.vue'

export default defineComponent({
  name: 'ViewAcceptedOfferDialog',
  components: {
    HbImage,
    NameInitialsImage,
    DateFormat,
    HbFormGroupCol,
    HbFormGroup,
    HbDialogBody,
    HbBtn,
    HbDialogActions,
    HbDialogFooter,
    HbDialogHeader,
    HbDialog,
  },
  mixins: [DialogMixin],
  props: {
    offer: { type: Object as () => Offer, required: true },
    property: { type: Object as () => PropertyListItem, required: true },
  },
  setup(props) {
    const dialog = useDialog(props)
    const isDefined = (value: unknown) => !isUndefined(value)

    return {
      dialog,
      isDefined,
    }
  },
})
</script>

<style lang="scss">
.offer__from-user {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-right: auto;

  &__name {
    font-size: 14px;
    color: rgba($blue1, 0.5);
  }

  &__image {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
