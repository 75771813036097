<template>
  <div>
    <Splide
      :has-track="false"
      :options="cardCarouselSettings"
      class="home-card-carousel"
      @splide:mounted="emitReady()"
      @splide:move="onMove"
    >
      <div class="custom-wrapper">
        <SplideTrack>
          <template v-if="imgSrcList.length">
            <SplideSlide
              v-for="(item, index) in imgSrcList"
              :key="index"
              :index="index"
            >
              <home-image
                class="slide-img"
                :image="item"
                :allow-image-preload="imagePreloadIsAllowed(index)"
                @click="navigate"
              />
            </SplideSlide>
          </template>
          <template v-else>
            <SplideSlide :index="0">
              <home-image
                class="slide-img"
                :image="placeholderItem"
                @click="navigate"
              />
            </SplideSlide>
          </template>
        </SplideTrack>

        <div class="splide__arrows" />
      </div>
    </Splide>
  </div>
</template>

<script lang="ts">
import type { Options as SplideOptions } from '@splidejs/vue-splide'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import { useModel } from 'wue'
import { computed, ref } from 'vue'
import type { SrcSet } from '@homebourse/api-client'
import { useRouter } from 'vue-router'
import { useRoute } from '#app'
import { CarouselMixin } from '~/mixins'
import HomeImage from '~/modules/home/components/HomeImage.vue'
import { HOME_PLACEHOLDER_SRC_SET_ITEM } from '~/modules/home/constants'

export default {
  name: 'HomeCardCarousel',
  components: {
    HomeImage,
    Splide,
    SplideSlide,
    SplideTrack,
  },
  mixins: [CarouselMixin],
  props: {
    imgSrcList: { type: Array as () => SrcSet[], default: () => [] },
    homeUrl: { type: String, default: '/' },
    disableSlideOnMobile: { type: Boolean, default: () => false },
    enablePreload: { type: Boolean, default: () => true },
    loadedFrom: { type: String, default: '' },
  },
  emits: ['ready'],
  setup(props, ctx) {
    const router = useRouter()
    const route = useRoute()
    const modelCtx = useModel<number>(props, ctx)
    const carouselEl = ref<InstanceType<typeof Splide>>(null)
    const startIndex = computed(() => modelCtx.model.value || 0)
    const cardCarouselSettings = computed<SplideOptions>(() => ({
      type: 'loop',
      start: startIndex.value,
      drag: false,
      perPage: 1,
      pagination: false,
      breakpoints: {
        767: {
          destroy: props.disableSlideOnMobile,
        },
      },
    }))
    const placeholderItem = ref<SrcSet>({
      data: [HOME_PLACEHOLDER_SRC_SET_ITEM],
    })
    const emitReady = () => ctx.emit('ready')

    const navigate = () => {
      const target =
        route?.meta?.mustAuth || props.loadedFrom === 'project'
          ? '_blank'
          : '_self'
      if (props.homeUrl.startsWith('http'))
        return window.open(props.homeUrl, target)
      const routeUrl = router.resolve(props.homeUrl)
      window.open(routeUrl.href, target)
    }
    const imagePreloadIsAllowed = (index: number) => {
      if (!props.enablePreload) {
        return startIndex.value === index
      }
      return modelCtx.model.value <= index && index <= modelCtx.model.value + 2
    }
    const onMove = (_, index: number) => {
      modelCtx.model.value = index
    }

    return {
      cardCarouselSettings,
      carouselEl,
      placeholderItem,
      imagePreloadIsAllowed,
      navigate,
      emitReady,
      onMove,
      ...modelCtx,
    }
  },
}
</script>

<style lang="scss">
.home-carousel .home-card-carousel .splide__list {
  padding: 0 !important;
}
.home-card-carousel {
  position: relative;
  height: 100%;

  .slide-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  & * {
    height: 100%;
  }

  .carousel__slide {
    padding: 0;
  }

  .carousel__icon {
    font-size: 18px;
  }

  .splide__slide {
    margin: 0 !important;
  }

  .splide__list {
    padding: 0 !important;
    display: flex !important;
    overflow-x: visible !important;
  }

  .splide__arrows {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .splide__arrow {
    background: white !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
    border: 0;
    cursor: pointer;

    &--prev {
      transform: rotate(180deg);
    }

    svg {
      width: 12px;
    }

    &[disabled] {
      opacity: 0;
    }

    @include tablet {
      opacity: 1;
      border: 10px solid transparent;
      width: 50px;
      height: 50px;
      background-clip: content-box !important;

      &--prev {
        margin-left: -10px;
      }

      &--next {
        margin-right: -10px;
      }
    }
  }

  &:hover {
    .splide__arrow {
      &[disabled] {
        opacity: 0;
      }

      opacity: 1;
    }
  }
}
</style>
